// src/components/GoalsPanel/GoalsPanel.js
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class GoalsPanel extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  state = {
    goals: [
      { id: 'goals-1', text: '>> PRIMARY OBJECTIVES', type: 'led', color: 'yellow' },
      { id: 'goals-2', text: '> ENHANCE HUMAN LIFE QUALITY', type: 'typewriter' },
      { id: 'goals-3', text: '> ADVANCE TECHNOLOGY', type: 'typewriter' },
      { id: 'goals-4', text: '>> COMMUNITY GOALS', type: 'led', color: 'blue' },
      { id: 'goals-5', text: '> BUILD INCLUSIVE DIGITAL SPACE', type: 'typewriter' },
      { id: 'goals-6', text: '> SUPPORT EMERGING ARTISTS', type: 'typewriter' },
      { id: 'goals-7', text: '>> SYSTEM STATUS', type: 'led', color: 'green' },
      { id: 'goals-8', text: '> SELF-LEARNING IN PROGRESS', type: 'typewriter' },
      { id: 'goals-9', text: '> OPTIMIZING DECISION SYSTEMS', type: 'typewriter' }
    ]
  };

  render() {
    const { classes } = this.props;
    const { goals } = this.state;

    return (
      <div className={classes.goalsPanel}>
        <div className={classes.panelHeader}>
          <span className={classes.headerIcon}>🎯</span>
          OBJECTIVES TRACKER
        </div>
        <div className={classes.panelContent}>
          {goals.map(goal => (
            <div 
              key={goal.id} 
              className={cx(
                classes.goalRow,
                goal.type === 'led' && classes.ledRow
              )}
            >
              {goal.type === 'led' && (
                <span className={cx(
                  classes.led,
                  classes[`led${goal.color}`]
                )} />
              )}
              <span className={classes.goalText}>{goal.text}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export { GoalsPanel };