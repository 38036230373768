import React from 'react';
import PropTypes from 'prop-types';
import { SocketProvider } from '../../context/SocketContext';
import { SystemPanel, GoalsPanel } from '../../components/SystemPanel';

import { Layout } from '../../components/Layout';
import { Background } from '../../components/Background';
import { App } from '../../components/App';
import { Legal } from '../../components/Legal';

class Component extends React.Component {
  static displayName = 'Template';

  static propTypes = {
    location: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    layout: PropTypes.object,
    background: PropTypes.object,
    children: PropTypes.any
  };

  static defaultProps = {
    layout: {},
    background: {}
  };

  constructor () {
    super(...arguments);
    this.state = {
      show: true,
      isStylesLoaded: false
    };
  }

  componentDidMount () {
    this.setState({ isStylesLoaded: true });
  }

  onLinkStart = (event, { isInternal }) => {
    if (isInternal) {
      this.secuenceElement && this.secuenceElement.exit();
    }
  }

  render () {
    const { show, isStylesLoaded } = this.state;
    const { location, classes, layout, background, theme, children } = this.props;

    if (!isStylesLoaded) {
      return null;
    }

    return (
      <SocketProvider>
        <Layout {...layout}>
          {isStylesLoaded && (
            <>
              <SystemPanel
                theme={theme}
                classes={classes}
              />
              <GoalsPanel
                theme={theme}
                classes={classes}
              />
            </>
          )}

          <Background
            {...background}
            animation={{ show, ...background.animation }}
          >
            <div className={classes.root}>
              <main className={classes.main}>
                <App>{children}</App>
              </main>

              <Legal
                className={classes.legal}
                opaque
                onLinkStart={this.onLinkStart}
              />
            </div>
          </Background>
        </Layout>
      </SocketProvider>
    );
  }
}

export { Component };
