// src/components/SystemPanel/SystemPanel.js
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class SystemPanel extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  state = {
    systemInfo: {
      STATUS: 'ACTIVE',
      CPU_USAGE: '32%',
      MEMORY: '4.2TB/16TB',
      UPTIME: '127:14:52',
      NETWORK: 'CONNECTED',
      SECURITY: 'LEVEL 3',
      USER: 'ADMIN',
      VERSION: 'v2.3.1',
      CORE_TEMP: '42°C',
      PROCESSES: '124 ACTIVE'
    }
  };

  componentDidMount() {
    this.updateInterval = setInterval(this.updateSystemInfo, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  updateSystemInfo = () => {
    // 模拟更新系统信息
    this.setState(prevState => ({
      systemInfo: {
        ...prevState.systemInfo,
        CPU_USAGE: `${Math.floor(Math.random() * 100)}%`,
        UPTIME: this.updateUptime(prevState.systemInfo.UPTIME)
      }
    }));
  };

  updateUptime = (uptime) => {
    const [hours, minutes, seconds] = uptime.split(':').map(Number);
    let newSeconds = seconds + 1;
    let newMinutes = minutes;
    let newHours = hours;

    if (newSeconds >= 60) {
      newSeconds = 0;
      newMinutes++;
    }
    if (newMinutes >= 60) {
      newMinutes = 0;
      newHours++;
    }

    return `${newHours}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
  };

  render() {
    const { classes } = this.props;
    const { systemInfo } = this.state;

    return (
      <div className={classes.systemPanel}>
        <div className={classes.panelHeader}>
          <span className={classes.headerIcon}>⚡</span>
          SYSTEM MONITOR
        </div>
        <div className={classes.panelContent}>
          {Object.entries(systemInfo).map(([key, value], index) => (
            <div key={key} className={classes.infoRow}>
              <span className={classes.infoLabel}>{key}</span>
              <span className={cx(
                classes.infoValue,
                {[classes.activeValue]: key === 'STATUS' && value === 'ACTIVE'}
              )}>
                {value}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export { SystemPanel };