// src/components/SystemPanel/SystemPanel.styles.js
const styles = theme => ({
  systemPanel: {
    position: 'fixed',
    left: 140,
    top: 180,
    width: 280,
    height: '600px',
    zIndex: 100,
    padding: 20,
    color: '#666666',
    fontFamily: theme.typography.primary,
    fontSize: '10px',
    pointerEvents: 'none',
    userSelect: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -16,
      top: 0,
      height: '100%',
      width: 2,
      background: 'linear-gradient(to bottom, transparent, rgba(6,182,212,0.2), transparent)'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -8,
      top: 0,
      height: '100%',
      width: 1,
      background: 'linear-gradient(to bottom, transparent, rgba(236,72,153,0.2), transparent)'
    },
    '@media screen and (max-width: 1200px)': {
      display: 'none'
    },
    '@media screen and (max-height: 800px)': {
      display: 'none'
    }
  },
  
  panelHeader: {
    fontSize: 9,
    textTransform: 'uppercase',
    color: 'rgba(6,182,212,0.5)',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 15,
    fontFamily: theme.typography.primary,
    fontWeight: 600,
    '&::before': {
      content: '""',
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: 'rgba(6,182,212,0.5)',
      animation: '$pulse 2s infinite'
    }
  },

  headerIcon: {
    fontSize: 12
  },

  panelContent: {
    marginLeft: 12
  },

  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    transition: 'color 0.3s',
    '&:hover': {
      color: 'rgba(6,182,212,0.5)'
    }
  },

  infoValue: {
    color: '#4a4a4a',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    opacity: 0.8,
    fontWeight: 400,
    fontSize: 8,
    fontFamily: theme.typography.primary,
    '&::before': {
      content: '[',
      marginRight: 3,
      color: 'rgba(6,182,212,0.4)'
    },
    '&::after': {
      content: ']',
      marginLeft: 3,
      color: 'rgba(6,182,212,0.4)'
    }
  },

  infoLabel: {
    color: '#4a4a4a',
    letterSpacing: '0.1em',
    fontWeight: 400,
    fontSize: 7,
    fontFamily: theme.typography.primary,
    opacity: 0.85,
    transition: 'all 0.3s ease',
    '&:hover': {
      color: 'rgba(6,182,212,0.8)',
      textShadow: `
        0 0 5px rgba(6,182,212,0.5),
        0 0 10px rgba(6,182,212,0.3),
        0 0 15px rgba(6,182,212,0.2)
      `,
      transform: 'translateX(-2px)'
    }
  },

  activeValue: {
    color: '#4CAF50',
    fontSize: 7,
    animation: '$glitch 1s linear infinite',
    textShadow: `
      2px 0 0 rgba(6,182,212,0.3),
      -2px 0 0 rgba(236,72,153,0.3),
      0 0 8px rgba(76,175,80,0.5)
    `,
    '&::before': {
      content: '">>"',
      marginRight: 6,
      color: 'rgba(76,175,80,0.6)'
    }
  },

  '@keyframes pulse': {
    '0%': { opacity: 0.6 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.6 }
  },

  '@keyframes glitch': {
    '0%, 100%': { transform: 'translate(0)' },
    '20%': { transform: 'translate(-2px, 2px)' },
    '40%': { transform: 'translate(-2px, -2px)' },
    '60%': { transform: 'translate(2px, 2px)' },
    '80%': { transform: 'translate(2px, -2px)' }
  },

  gridOverlay: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `
        linear-gradient(rgba(6,182,212,0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(6,182,212,0.03) 1px, transparent 1px)
      `,
      backgroundSize: '20px 20px',
      pointerEvents: 'none'
    }
  },

  cornerDecoration: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 10,
      top: 10,
      width: 15,
      height: 1,
      background: 'rgba(6,182,212,0.3)'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 10,
      top: 10,
      width: 1,
      height: 15,
      background: 'rgba(6,182,212,0.3)'
    }
  },

  scanline: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(transparent 50%, rgba(6,182,212,0.02) 50%)',
      backgroundSize: '100% 4px',
      animation: '$scan 10s linear infinite',
      pointerEvents: 'none'
    }
  },

  dataStream: {
    position: 'absolute',
    right: -20,
    top: '50%',
    width: 1,
    height: '30%',
    background: 'linear-gradient(to bottom, transparent, rgba(6,182,212,0.2), transparent)',
    animation: '$dataFlow 4s linear infinite'
  },

  '@keyframes scan': {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(20px)' }
  },

  '@keyframes dataFlow': {
    '0%': { transform: 'translateY(-100%)' },
    '100%': { transform: 'translateY(100%)' }
  }
});

export { styles };