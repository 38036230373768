// src/components/GoalsPanel/GoalsPanel.styles.js
const styles = theme => ({
  goalsPanel: {
    position: 'fixed',
    right: 140,
    top: 180,
    width: 280,
    height: '600px',
    zIndex: 100,
    padding: 20,
    color: '#666666',
    fontFamily: theme.typography.primary,
    pointerEvents: 'none',
    userSelect: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      right: -16,
      top: 0,
      height: '100%',
      width: 2,
      background: 'linear-gradient(to bottom, transparent, rgba(6,182,212,0.2), transparent)'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -8,
      top: 0,
      height: '100%',
      width: 1,
      background: 'linear-gradient(to bottom, transparent, rgba(236,72,153,0.2), transparent)'
    },
    '@media screen and (max-width: 1200px)': {
      display: 'none'
    },
    '@media screen and (max-height: 800px)': {
      display: 'none'
    }
  },

  panelHeader: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: 'rgba(236,72,153,0.5)',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    justifyContent: 'flex-end',
    marginBottom: 15,
    fontFamily: theme.typography.primary,
    fontWeight: 600,
    '&::after': {
      content: '""',
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: 'rgba(236,72,153,0.5)',
      animation: '$pulse 2s infinite'
    }
  },

  goalRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 12,
    transition: 'color 0.3s',
    '&:hover': {
      color: 'rgba(236,72,153,0.5)'
    }
  },

  ledRow: {
    fontWeight: 500,
    marginTop: 20,
    color: '#4a4a4a',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    opacity: 0.9,
    fontSize: 9,
    fontFamily: theme.typography.primary,
    '&::before': {
      content: '"|"',
      marginRight: 6,
      color: 'rgba(236,72,153,0.4)'
    }
  },

  led: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    marginLeft: 8,
    animation: '$blink 1.5s ease-in-out infinite',
    opacity: 0.7
  },

  ledyellow: {
    backgroundColor: '#FFD700',
    boxShadow: '0 0 6px rgba(255,215,0,0.4)'
  },

  ledblue: {
    backgroundColor: '#88C0D0',
    boxShadow: '0 0 6px rgba(136,192,208,0.4)'
  },

  ledgreen: {
    backgroundColor: '#4CAF50',
    boxShadow: '0 0 6px rgba(76,175,80,0.4)'
  },

  goalText: {
    color: '#4a4a4a',
    letterSpacing: '0.1em',
    fontWeight: 400,
    fontSize: 8,
    fontFamily: theme.typography.primary,
    transition: 'all 0.3s ease',
    '&:hover': {
      color: 'rgba(236,72,153,0.8)',
      textShadow: `
        0 0 5px rgba(236,72,153,0.5),
        0 0 10px rgba(236,72,153,0.3),
        0 0 15px rgba(236,72,153,0.2)
      `,
      transform: 'translateX(2px)'
    }
  },

  '@keyframes pulse': {
    '0%': { opacity: 0.6 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.6 }
  },

  '@keyframes blink': {
    '0%, 100%': { opacity: 1, transform: 'scale(1)' },
    '50%': { opacity: 0.6, transform: 'scale(0.8)' }
  }
});

export { styles };