const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  brand: {
    margin: [0, 'auto', 20],
    padding: [10, 0],
    width: '100%',
    maxWidth: 700
  },
  main: {
    flex: 1,
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: '0',
    '@media screen and (max-width: 768px)': {
      padding: '0'
    }
  },
  legal: {
    position: 'relative',
    marginTop: 20,
    textAlign: 'center',
    '@media screen and (max-width: 768px)': {
      position: 'static',
    }
  },
  enterOverlay: {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    padding: 20,
    backgroundColor: '#000000'
  },
  enterElement: {
    margin: 'auto',
    fontFamily: 'monospace',
    '& button': {
      fontFamily: 'monospace'
    }
  }
});

export { styles };
