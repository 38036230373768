import React from 'react';
import io from 'socket.io-client';

export const SocketContext = React.createContext(null);

export class SocketProvider extends React.Component {
  state = {
    socket: null,
    connected: false,
    username: '',
    userId: '',
    isReady: false
  };

  componentDidMount () {
    if (!this.state.socket) {
      const SOCKET_URL = 'https://echoconsole.com:8443';

      // const SOCKET_URL = 'https://soulterminal.com:8443';
      // const SOCKET_URL = 'http://localhost:8443';

      const socket = io(SOCKET_URL, {
        withCredentials: true,
        transports: ['websocket']
      });

      socket.on('connect', () => {
        console.log('Connected to server');
        this.setState({
          socket,
          connected: true,
          isReady: true
        });
      });

      socket.on('usernameAssigned', ({ userId, username }) => {
        this.setState({ username, userId });
      });
    }
  }

  render () {
    return (
      <SocketContext.Provider value={this.state}>
        {this.props.children}
      </SocketContext.Provider>
    );
  }
}
